/* different gift offer*/

@font-face {
    font-family: 'The New Elegance';
    src: url('../Fonts/TheNewElegance-Italic.otf') format('opentype');
}
@media (min-width: 1200px) {
    #desktop-difofer {
        margin-top: -50px;
    }
    .choosetext {
        margin: 0;
    }
    .ship-modal-div {
        display: flex;
        align-items: center;
    }
    img#badge-btn-image {
        width: 90px;
    }
    #badge3 {
        width: 100px;
        height: 100px;
        display: flex;
        margin-top: -45px;
    }
    #modal-content2 {
        inset: 85px;
    }
    #closemodal-badge2 {
        position: absolute;
        right: 20px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        top: 40px;
    }

    .differentOffer {
        display: flex;
        justify-content: center;
        width: 900px;
        height: 300px;
        align-items: center;
    }

    .textside {
        width: 50%;
        color: white;
        padding: 35px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
    }

    #textside-cw {
        background-color: #66aa47;
    }

    #textside-neck {
        background-color: #eb5b24;
        padding: 20px !important;
    }

    #span-neck {
        color: #9e2c00;
    }

    p.main-offer-txt {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0;
        padding-right: 40px;
        margin-left: 0;
    }

    p.plans-span {
        color: #1b6047;
        font-weight: 600;
        text-align: left;
        margin-left: 0;
    }

    .get-free-gifts {
        color: white;
        text-decoration: none;
        margin-left: 0;
        justify-content: space-between;
        display: flex;
        width: 40%;
    }

    .img-for-offer {
        width: 50%;
    }

    img.extraoferta {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-left: -50px;
        margin-top: -10px;
    }

    #mobile-difofer {
        display: none;
    }
}
.thebade {
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: -50px;
    display: none;
}
.draw-offer-homepage a {
    color: white;
    text-decoration: none;
}
@media (max-width: 1200px) {
    .ship-modal-div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    img#badge-btn-image {
        width: 90px;
    }
    .thebade {
        right: -30px;
    }
    #badge3 {
        width: 100px;
        height: 100px;
        display: flex;
        margin-top: -45px;
    }
    .draw-offer-homepage p {
        font-size: 13px;
    }
    #modal-content2 {
        inset: 85px;
    }
    #closemodal-badge2 {
        position: absolute;
        right: 20px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        top: 40px;
    }
    .differentOffer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        background: #66aa47;
        width: 300px;
        border-radius: 25px;
    }

    .neck-color {
        background: #66aa47;
    }

    .cw-color {
        background: #eb5b24;
    }

    #textside-cw {
        background-color: #66aa47;
    }

    #textside-neck {
        background-color: #eb5b24;
    }

    #span-neck {
        color: #9e2c00;
    }

    .textside {
        color: white;
        padding: 20px;
        border-radius: 0px 0px 25px 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    p.main-offer-txt {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0;
    }

    p.plans-span {
        color: #1b6047;
        font-weight: 600;
        text-align: left !important;
    }

    .get-free-gifts {
        color: white;
        text-decoration: none;
        justify-content: space-between;
        display: flex;
        width: 60%;
    }

    .img-for-offer {
        width: 100%;
    }

    img.extraoferta {
        width: 300px;
        height: auto;
    }

    .offer-mobiletxt {
        text-align: center;
        padding: 10px;
        font-size: 20px;
        color: white;
        font-weight: 500;
        margin-bottom: 0;
    }
}

/* end of different gift offer*/

/* ships next  */

.handbag-title {
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 1px;
    color: black;
}
.handbag-display {
    display: flex;
    align-items: center;
    /* width: 40%; */
    justify-content: start;
    padding-top: 10px;
}
.handbag-display a {
    border: 1px solid #86a141;

    display: block;

    background: linear-gradient(to right, white 50%, #86a141 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    border-radius: 8px;
    color: white;
    text-decoration: unset;
    transition: all 0.5s ease-out;
    padding: 20px 25px;
    margin: 0px 10px 0px 0px;
}

.handbag-display a:hover {
    background-position: left bottom;
    color: #86a141;
}
.handbag-display p {
    margin: 0px 0px 0px 10px;
    font-size: 14px;
    cursor: pointer;
    color: black;
}
p.txt-head {
    font-weight: 500 !important;
    margin: 0;
    color: #056f07;
}
.ship-left,
.ship-right {
    width: 49%;
}
.ship-right img {
    width: 100%;
}
.txt-img {
    width: 200px;
    object-fit: cover;
    margin-top: 5px;
}

.txt-paragraph {
    font-size: 18px !important;
    margin: 0;
    color: black;
    /* display: none; */
}

.mobile-ship {
    display: none;
}
.desPhoto {
    display: block;
}
.mobilePhoto {
    display: none;
}
/* -- end of  ships next  */

/* -- main styling  */
.first-section {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 30px 0;
    background-position: center;
}
.Offer-first-section .bannerTitle,
.Offer-first-section p {
    color: black !important;
}
.Offer-first-section .section-container {
    display: flex !important;
    justify-content: space-between;
    /* align-items: center; */
    background-color: #fdf7ee;
    position: relative;
    padding-bottom: 50px;
    height: 600px;
}
.Offer-first-section .first-section-content {
    width: 45% !important;
    text-align: left !important;
    padding: 0px !important;
    padding-top: 50px !important;
    padding-left: 100px !important;
}
.offerImage {
    width: 650px;
    padding-bottom: -100px;
    padding-right: 100px;
    position: relative;
    right: 0;
    /* bottom: -150px; */
}
.offerImage img {
    width: 100%;
}
.first-section-content {
    width: 50%;
    margin-left: 0;
    text-align: center;
    padding: 0px 130px;
}

.bannerTitle {
    color: white;
    font-size: 22px;
    font-weight: bold;
    margin-left: 5px;
    text-align: center;
    margin: 0;
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 20px;
    width: 670px;
}
.popUp-buttons {
    display: flex;
    padding-top: 20px;
}
.popUp-buttons a {
    width: 50% !important;
    text-align: center;
    padding: 8px 5px !important;
    margin-right: 5px !important;
}
.popUp-buttons a:nth-child(1) {
    background: #056f07 !important;
    border: 1px solid #056f07 !important;
    transition: 0.3s;
}
.popUp-buttons a:nth-child(1):hover {
    color: black;
    background: white !important;
}
.popup-content {
    text-align: center;
    display: flex;
    align-items: center;
}
.popup-content iframe {
    width: 60%;
    height: 500px;
}
.popup-content img {
    height: 50px;
    margin: unset !important;
}
.popUp-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    padding: 0px 0px 0px 20px;
}
.popUp-scroll {
    padding-top: 10px;
    height: 300px;
    overflow-y: scroll;
}
.popUp-scroll::-webkit-scrollbar {
    width: 6px;
}

.popUp-scroll::-webkit-scrollbar-thumb {
    background-color: #ec6e55;
    border-radius: 4px;
}

.popUp-scroll::-webkit-scrollbar-track {
    background-color: white;
}
.popUp-description div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: unset !important;
}
.popUp-description p {
    margin: unset !important;
}
.popup-content h3 {
    margin-top: 0;
}

.popup-content p {
    margin-bottom: 20px;
}

.popup-content button {
    position: absolute;
    right: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    border: unset;
    background: unset;
    top: 0px;
}
.popup-content video {
    height: 500px;
}
.bannerTitle h2 {
    font-size: 16px;
    margin: 0;
}

.bannerParagraph p {
    color: white;
}
.bannerParagraph h3 {
    color: white;
}
.bannerTitle h1 {
    font-family: 'The New Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
}
.play-popUp {
    position: relative;
    cursor: pointer;
}
.play-popUp:hover .play-content .hoverable {
    color: #eb5b24;
}
.membertab-heading {
    text-align: center;
    padding-bottom: 20px;
}
.membertab-heading p {
    margin-bottom: 0px;
}
.membertab-heading h1 {
    font-family: 'The New Elegance ';
    font-style: italic;
    font-weight: bold;
    line-height: 40px;
    font-size: 27px;
}
.member-tab {
    display: flex;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    border: 3px solid white;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
}
.member-position {
    position: absolute;
    top: 0px;
    background: #ff7300;
    font-size: 12px;
    top: -9px;
    left: 115px;
    font-family: 'The New Elegance ';
    font-style: italic;
    font-weight: bold;
    color: white;
    width: 100px;
    border-radius: 4px;
}
.member-price {
    display: flex;
    font-style: italic;
    align-items: flex-end;
}
.member-tab span {
    color: #676767;
    font-weight: bold;
    margin: 0px;
    font-family: 'The New Elegance ';
}
.member-price h1 {
    margin: 0px;
    font-family: 'The New Elegance ';
}
.member-tab p {
    margin-bottom: 0px;
    font-family: 'roboto';
}
.memebrTab-display {
    width: 55%;
}
.membere-mmonth {
    font-weight: bold;
    margin: 0px;
    padding: 0px 20px;
    border-right: 2px solid black;
}
.membere-mmonth h1 {
    margin-bottom: 0px;
    font-family: 'The New Elegance ';
}
.member-button {
    background-color: #eb5b24;
    color: white;
    width: 100%;
    padding: 10px;

    transition: 0.3s;

    border-radius: 8px;

    background: linear-gradient(to right, white 50%, #eb5b24 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
    border: 2px solid #eb5b24;
}
.member-button:hover {
    background-position: left bottom;
    color: #ec6e55;
}
.member-free {
    font-size: 12px;
    color: #ff7300;
    margin-bottom: 0px;
}
.play-content {
    position: absolute;
    bottom: 0;
    text-align: center;
    font-family: 'The New Elegance ';
    font-style: italic;
    font-weight: bold;
    color: white;
}
.play-content .hoverable {
    display: flex;
    align-items: center;
    justify-content: center;
}
.play-content .hoverable svg {
    margin: unset;
    margin: 5px;
}
.play-content .hoverable:hover {
    color: #eb5b24;
}
/* banner buttons  */
.bannerBtns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    align-items: center;
    margin: 0 !important;
}
.christmasBtn {
    justify-content: center;
}
.bannerBtns a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white !important;
}

.button1,
.button2 {
    width: 50%;
    height: 65px;
    border-radius: 7px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}

.button1 {
    margin-right: 10px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid #eb5b24;

    background: linear-gradient(to right, white 50%, #eb5b24 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
}
.button1:hover {
    background-position: left bottom;
    color: #ec6e55 !important;
}

.button2 {
    border: 1px solid white;

    background: linear-gradient(to right, white 50%, #86a141 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
}
.button2:hover {
    background-position: left bottom;
    color: #86a141 !important;
}

.dream {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    color: white;
    text-decoration: unset !important;
    transition: 0.3s;
}
.dream2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    color: white;
    text-decoration: unset !important;
    transition: 0.3s;
    position: absolute;
    bottom: 0;
}
.dream:hover {
    color: #1b572c;
}
.dream2:hover {
    color: #1b572c;
}
.dream p:nth-child(1) {
    margin: unset;
    padding-top: 10px !important;
}
.dream p:nth-child(2) {
    margin: unset;
    font-size: 14px;
    padding-bottom: 20px !important;
}

.dream2 p {
    margin: unset;
    padding: 20px 0px !important;
}
.dream img,
.dream2 img {
    width: 60%;
    border-radius: 8px;
    margin-bottom: -100px !important;
    margin: auto;
}

.ensure {
    font-size: 16px;
    color: #0b3144;
    padding-bottom: 5px;
}

.counter {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 65px;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.counter p {
    font-size: 12px;
    color: white !important;
    padding-bottom: 6px;
    margin: 0 !important;
}

.counter h1 {
    font-size: 30px;
    color: white;
    margin: 5px;
}

#trust-pilot {
    margin-top: 0px;
    padding: 20px 0;
    height: 23%;
}

.days,
.hours,
.minutes,
.sec {
    width: 60px;
    height: 60px;
    background-color: #1b572c;
    color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0;
}

.dots {
    color: white;
    font-size: 50px;
}

/* different gift offer*/

.choosetext {
    margin: 0;
}

.differentOffer {
    display: flex;
    justify-content: center;
    width: 900px;
    height: 300px;
    align-items: center;
}

.textside {
    width: 50%;
    color: white;
    padding: 20px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

#textside-cw {
    background-color: #66aa47;
}

#textside-neck {
    background-color: #eb5b24;
    padding: 20px !important;
}

#span-neck {
    color: #9e2c00;
}

p.main-offer-txt {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    padding-right: 40px;
    margin-left: 0;
}

p.plans-span {
    color: #1b6047;
    font-weight: 600;
    text-align: left;
    margin-left: 0;
}

.get-free-gifts {
    color: white;
    text-decoration: none;
    margin-left: 0;
    justify-content: space-between;
    display: flex;
    width: 40%;
}

.img-for-offer {
    width: 48%;
}

img.extraoferta {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-left: -50px;
    padding: 10px;
}
.homepage-slider {
    width: 1200px;
    margin: auto;
}
.homepage-slider img {
    width: 100%;
}
.verified {
    width: 700px;
    text-align: center;
    padding-top: 50px;
}
.verified-customer {
    color: #b43f09;
    font-weight: bold;
    font-size: 18px !important;
}
.verified h1 {
    color: #b43f09;
}
.verified p {
    padding-top: 10px;
    font-size: 20px;
}
.verified img:nth-child(1) {
    height: 40px;
}
.verified img {
    height: 30px;
}
.experience-bck {
    background-color: #fdf7ee;
    padding: 20px 0px;
}

.member-bck {
    padding: 20px 0px;
    background-color: #faf3ef;
}

.experience,
.member-container {
    width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.experience-content {
    width: 60%;
    font-family: 'The New Elegance ';
}

.experience-btn {
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.play-content h1:nth-child(1) {
    font-size: 20px;
}
.play-content {
    left: 0;
    right: 0;
}
.experience-btn a {
    width: 49%;
    text-decoration: unset;
    padding: 15px 0px;
    color: white;
    border-radius: 8px;
    /* font-weight: bold; */
    /* font-size: 13px; */
}
.experience h1 {
    font-family: 'The New Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
}

.experience-btn1 {
    border: 1px solid #ec6e55;

    display: block;

    background: linear-gradient(to right, white 50%, #ec6e55 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
}
.experience-btn1:hover {
    background-position: left bottom;
    color: #ec6e55;
}
.member-ac {
    border: 3px solid #ff7300;
}
.experience-btn2 {
    border: 1px solid #86a141;

    display: block;

    background: linear-gradient(to right, white 50%, #86a141 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
}
.experience-btn2:hover {
    background-position: left bottom;
    color: #86a141;
}
.member-container a {
    border: 1px solid #ec6e55;

    display: block;
    width: 300px;
    text-align: center;
    background: linear-gradient(to right, white 50%, #ec6e55 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
    margin: unset;
}
.member-container a:hover {
    background-position: left bottom;
    color: #ec6e55;
}
.why-ireland {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    padding-bottom: 100px;
}

.circle-display {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
}
.circle-heading {
    font-family: 'The New Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
    text-align: center;
    padding: 50px 0px;
}
.circle-bck {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #faf3ef;
    cursor: pointer;
}
@keyframes bounce {
    0%,
    100% {
        transform: translateY(0) scale(1.2);
    }
    25%,
    75% {
        transform: translateY(-10px) scale(1.2);
    }
    50% {
        transform: translateY(0) scale(1.1);
    }
}

.circle-active {
    transform: scale(1.2);
    transition: 0.3s;
    animation: bounce 1.1s;
}
.big-circle {
    background-color: #faf3ef;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
}
.big-circle h1 {
    color: #ec6e55;
    font-family: 'The New Elegance';
    font-style: italic;
    font-weight: bold;
    font-size: 40px;
}
.big-circle p {
    font-weight: bold;
    color: #4d4d4d;
}
.experience-video {
    width: 35%;
}
.experience-video iframe {
    width: 100%;
    border-radius: 10px;
}
.experience-video video {
    width: 90%;
    border-radius: 8px;
    margin: auto;
    display: flex;
}
.member-container h1 {
    font-family: 'The New Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
}
.play-content h1 {
    font-size: 13px;
}
.kath {
    padding-left: 5px;
}
.member-container h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0px;
}
.member-container a {
    background-color: #ec6e55;
    color: white;
    padding: 15px 20px;
    text-decoration: none;
    border-radius: 8px;
}
/* end of different gift offer*/

/* ships next  */
.nextship {
    display: flex;

    border-radius: 10px;
    margin: 20px auto;
    flex-direction: row;
    padding: 15px;
    padding-top: 100px;
    align-items: center;
    width: 100%;
    color: white;
}
.nextship h1 {
    padding-bottom: 10px;
}
p.txt-head {
    font-weight: 500 !important;
    font-size: 14px;
}

.txt-img {
    width: 200px;
    object-fit: cover;
    margin-top: 5px;
}
.memebr-display ul,
.memebr-display li {
    list-style: none;
    padding-left: 0px;
}
.memebr-display li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 5px;
}
.memebr-display p {
    margin-bottom: 0px;
}
.memebr-display li img {
    width: fit-content;
    margin: 0px;
}
.memebr-display img {
    height: 12px;

    padding-right: 5px;
}
.memebr-display li p {
    margin: 0px;
}
.memebr-display li div {
    margin: 0px;
}
/* -- end of  ships next  */

/*trustbox styling*/
.truspilot-heading {
    text-align: center;
    padding: 150px 0px 20px 0px;
    font-family: 'The New Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
}

.trust-pilot-homepage {
    margin-top: 0px !important;
    padding-bottom: 100px;
}

.banner-mobile {
    display: none;
}

.notice {
    display: none;
}

div#desktop-difofer2 {
    display: none;
}

.personalizedBck {
    border-radius: 23px;
    background: #fdf7ee;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
}
.personalizedBck div {
    width: 45%;
}
.personalizedBck img {
    width: 100%;
}
.personalizedBck a {
    padding: 15px 25px;
    color: #ffffff;
    border: none;
    border-radius: 7px;
    font-size: 19px;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 50px;
    margin-top: 190px;
    background: linear-gradient(to right, white 50%, #ec6e55 50%);
    background-size: 200% 100%;
    border: 1px solid #ec6e55;
    background-position: right bottom;
    transition: all 0.5s ease-out;
}
.personalizedBck a:hover {
    background-position: left bottom;
    color: #ec6e55 !important;
}
.personalizedBck span {
    color: #000;
    border-radius: 4px;
    background: #ffec19;
    padding: 4px;
    font-weight: bold;
    font-family: 'The New Elegance';
}
.personalizedBck h2 {
    color: #d34a2f;
    font-weight: 700;
    font-family: 'The New Elegance';
    padding-top: 5px;
}
.personalizedBck h4 {
    font-family: 'The New Elegance';
    font-weight: 700;
    font-size: 18px;
}
.personalizedBck p:nth-child(5) {
    padding-bottom: 20px;
}
/*TABLET STYLING */
@media screen and (max-width: 1199px) {
    .notice {
        display: block;
        color: white;
        font-size: 14px;
    }
    .dream,
    .dream2 {
        position: relative;
    }
    .Offer-first-section .section-container {
        justify-content: center;
        height: auto;
    }
    .differentOffer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        flex-direction: column-reverse;
        background: #eb5b24;
        width: 80%;
        border-radius: 25px;
        height: auto;
        margin: 10px auto;
    }

    div#desktop-difofer2 {
        background: #66aa47;
        margin-top: 20px;
        display: none;
    }

    #desktop-difofercw {
        background-color: #66aa47;
    }

    .truspilot-heading {
        padding: 50px 0px 20px 0px;
    }

    .neck-color {
        background: #66aa47;
    }

    .cw-color {
        background: #eb5b24;
    }

    #textside-cw {
        background-color: #66aa47;
    }

    #textside-neck {
        background-color: #eb5b24;
    }

    #span-neck {
        color: #9e2c00;
    }

    .textside {
        color: white;
        padding: 20px;
        border-radius: 0px 0px 25px 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        width: 100%;
    }

    p.main-offer-txt {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0;
    }

    p.plans-span {
        color: #1b6047;
        font-weight: 600;
        text-align: center;
    }

    .get-free-gifts {
        color: white;
        text-decoration: none;
        justify-content: space-between;
        display: flex;
        width: 60%;
    }

    .img-for-offer {
        width: 100%;
    }

    img.extraoferta {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0px;
    }

    .offer-mobiletxt {
        text-align: center;
        padding: 10px;
        font-size: 20px;
        color: white;
        font-weight: 500;
    }
    .first-section-content {
        width: 100%;
        padding: 0;
    }

    .first-section-content h1 {
        padding: 10px 10px 0px;
        color: #0b3144;
    }

    .first-section-content p {
        padding: 0px 10px 0px;
        color: #0b3144 !important;
    }

    .first-section {
        padding: 0px 0px 30px 0px;
    }

    .banner-mobile {
        display: block;
        width: 100%;
    }

    .nextship {
        flex-direction: column;
    }
    .handbag-title {
        font-size: 24px;
        text-align: center;
    }
    .ship-left,
    .ship-right {
        width: 95%;
    }
    .txt-head {
        color: #fff;
    }

    .txt-paragraph {
        color: black !important;
        text-align: center;
        padding: 20px 0px;
    }

    .firstSection-homepage-image {
        width: 160%;
        margin-left: -30%;
    }

    .banner-link {
        width: 100%;
        height: unset;
    }

    .first-section {
        background-image: none !important;
        background-color: white;
    }

    .button2 {
        border: 1px solid #1b572c;
        color: #1b572c !important;
    }

    .button2:hover {
        background-color: transparent;
        color: #0b3144;
    }

    .ensure {
        color: white;
        margin: 0;
        text-align: center;
    }

    div.title:nth-child(1) {
        text-align: center;
        width: 87%;
    }

    .counter p {
        font-size: 10px;
    }
    .dream img,
    .dream2 img {
        margin-bottom: unset !important;
        width: 95%;
        margin: auto !important;
    }
    .experience,
    .member-container {
        width: 650px;
    }
    .homepage-slider {
        width: 80%;
    }
    .why-ireland {
        width: 90%;
        flex-direction: column;
    }
    .circle-display {
        padding-top: 20px;
    }
    .offerImage img {
        display: none;
    }
    .Offer-first-section .first-section-content {
        width: 100% !important;
        padding-right: 100px !important;
    }
    .Offer-first-section .section-container {
        display: block !important;
    }
    .personalizedBck {
        width: 90%;
        flex-direction: column;
    }
    .personalizedBck div {
        width: 100%;
    }
    .persImage {
        padding-top: 40px;
    }
}

/*MOBILE STYLING */
@media screen and (max-width: 768px) {
    .bannerBtns {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 130px;
        align-items: center;
        padding-top: 5px;
        margin: 0px 0 0px 0 !important;
    }
    .nextship {
        flex-direction: column;
    }
    .personalizedBck {
        padding: 20px 10px;
    }
    .button1,
    .button2 {
        width: 330px;
        height: 50px;
        border-radius: 7px;
        margin: 5px 0;
        font-size: 16px;
        font-weight: 700;
    }
    .bannerParagraph h3 {
        color: black;
    }
    .fullWidth {
        min-height: fit-content !important;
    }
    .Offer-first-section .section-container {
        flex-direction: column !important;
    }
    .Offer-first-section .first-section-content {
        width: 100% !important;
        text-align: left !important;
        padding: 0px !important;
        padding-top: 0px !important;
        padding-left: 0px !important;
    }

    .experience-content {
        line-height: 25px;
    }
    .member-container a {
        margin: auto;
    }
    .homepage-slider {
        margin: auto;
    }
    .experience-video video {
        width: 60%;
    }
    .firstSection-homepage-image {
        width: 100%;
        margin: 0;
    }
    .mobile-ship {
        display: block;
    }
    .desktop-ship {
        display: none;
    }
    .experience,
    .member-container {
        width: 100%;
        flex-direction: column;
    }
    .experience-video {
        width: 90%;
    }
    .big-circle {
        width: 90%;
        height: 300px;
    }
    .experience-content,
    .verified,
    .memebr-display {
        width: 90%;
        margin: auto;
        text-align: center;
        padding-top: 20px;
    }
    .verified {
        padding-top: 20px;
    }

    .circle-display {
        grid-template-columns: repeat(2, 1fr);
    }

    .memebr-display li {
        line-height: 25px;
        padding-bottom: 10px;
    }
    .popup-content {
        flex-direction: column;
    }
    .popup {
        width: 90%;
    }
    .popup-content iframe {
        width: 100%;
        padding-bottom: 20px;
    }
    .popUp-description {
        padding: 0px;
    }
    .popup-content iframe {
        height: 200px;
    }
    .popUp-scroll {
        height: 200px;
    }
    .popUp-buttons a:nth-child(1) {
        font-size: 15px;
    }
    .memebrTab-display {
        width: 90%;
        padding-top: 20px;
    }
    .handbag-display a {
        width: 50%;
        margin-right: 0px;
        text-align: center;
        padding: 10px;
    }
    .nextship {
        padding-top: 15px;
    }
    .handbag-display p {
        text-align: center;
        width: 49%;
    }
    p.txt-head {
        text-align: center;
    }
    .desPhoto {
        display: none;
    }
    .mobilePhoto {
        display: block;
    }
    .memebr-display li {
        align-items: flex-start;
    }
    .memebr-display li p {
        text-align: left;
        margin: 0px;
        margin-top: -7px;
    }
    .member-container h1 {
        padding-bottom: 10px;
    }
}

@media (min-width: 2000px) {
    .first-section {
        background-size: cover;
        background-repeat: no-repeat;
        height: 50vh !important;
        padding: 30px 0;
        background-position: 50%;
        display: flex !important;
        align-items: center !important;
    }
}
