.promo-container {
    background: linear-gradient(rgba(16, 103, 19, 0.8), rgba(16, 103, 19, 0.8)),
        url('../../../../assets/bg-image-2.png') no-repeat center center/cover;

    color: white;
    text-align: center;
    padding: 40px;
    border-radius: 30px;
    max-width: 1200px;
    margin: auto;
    font-family: 'Arial', sans-serif;
    position: relative;
    margin-top: 120px;
}
.promoCont {
    width: 60%;
    margin: auto;
}

.promo-header {
    background: #ffe600;
    color: #000;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
}

.promo-title {
    font-size: 42px;
    font-weight: bold;
    margin: 20px 0;
    line-height: normal;
    text-align: center;
}

.promo-button {
    background: #ff6200;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin: 20px 0;
    text-align: center;
}

.promo-button:hover {
    background: #ff4500;
}

.promo-text {
    font-size: 16px;
    margin: 20px 0;
    text-align: center;
}

.promo-list {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
    text-align: left;
    margin: 20px auto;
    max-width: 500px;
    text-align: center;
}

.promo-list li {
    margin: 10px 0;
}

.promo-bonus {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

.highlight {
    color: #ffe600;
    text-transform: uppercase;
}
@media (max-width: 1200px) {
    .promo-container {
        width: 95%;
        padding: 20px;
    }
    .promoCont {
        width: 100%;
    }
    .promo-title {
        font-size: 24px;
    }
}
@media (max-width: 768px) {
    .promo-container {
        width: 95%;
        padding: 20px;
    }
    .promoCont {
        width: 100%;
    }
    .promo-title {
        font-size: 22px;
    }
}
