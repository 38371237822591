.contact-us {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 100px;
}

.form-title {
    font-family: 'Kells';
    font-size: 40px;
    color: #1b572c;
    text-align: center;
    padding: 0 10px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#form-subtitle {
    color: #1b572c;
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    padding: 0 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 750px;
    height: fit-content;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.success-message {
    color: darkorange;
    margin-top: 114px;
    margin-bottom: 100px;
    text-align: center;
}

.success-message span {
    text-transform: capitalize;
    font-weight: bold;
}

.name-lastname {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 10px 0;
}

.name,
.last-name {
    width: 47%;
}

.label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

label {
    padding: 0 0 2px 5px;
    margin: 0;
    color: black;
    font-size: 12px;
}

.label p {
    padding: 0;
    margin: 0;
    color: #1b572c;
}

.contact-form input {
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 10px 10px;
    width: 100%;
}

.second-input {
    padding: 0;
    margin: 10px 0;
    width: 97%;
}

textarea {
    resize: none;
    border-radius: 10px;
    border: 1px solid #707070;
    width: 100%;
    height: 200px;
    padding: 10px;
}

textarea:focus {
    border: 1px solid #707070;
    outline: none;
}

.contact-submit {
    width: 97% !important;
    color: white;
    background-color: #48874c !important;
    border-radius: 7px !important;
    margin-top: 10px;
    cursor: pointer;
    -webkit-appearance: button !important;
    border: none;
    transition: 0.3s;
    height: 40px;
}

.contact-submit:hover {
    background-color: #eb5b24 !important;
}

.available,
.respond,
.exclude {
    color: #1b572c;
    text-align: center;
    font-size: 18px;
    padding: 0;
    width: 100%;
}

.available {
    margin-bottom: 50px;
}

.respond {
    margin-bottom: 25px;
}

::placeholder {
    opacity: 1;
}

:-ms-input-placeholder {
    color: #707070;
}

::-ms-input-placeholder {
    color: #707070;
}

.big-love {
    width: 30%;
    text-align: center;
}

.reasonDropdown {
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 1.5px;
    background-color: white;
}

.reasonDropdown>.Dropdown-menu {
    margin-top: 10px !important;
}

.reasonDropdown>.Dropdown-control {
    color: grey;
    font-size: 13px;
    padding: 6px 0 0 0 !important;
}

.Dropdown-placeholder.is-selected {
    color: black;
}

.Dropdown-option {
    font-size: 14px;
}

.second-input select {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #707070;
    border-radius: 10px;
    background-color: white;
}

.defaultValue {
    color: #707070;
}

.contact-us a {
    text-decoration: none;
    color: #1b572c;
}

@media screen and (max-width: 768px) {

    select,
    option {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}